export default {
  data() {
    return {
      headerSlides: [
        {
          id: 1,
          title: "<strong>Bérelj elektromos kisautót</strong> születésnapra",
          description: "Pici zsúr, vagy óriási party? Egy szülinapi száguldás négy keréken, az lesz az igazi!",
          image: "szulinap.jpg",
          actionButton: {
            moreDetails: "/szolgaltatasaink/berles-szuletesnapra/",
            bookAnAppointment: "#",
          },
        },
        {
          id: 2,
          title: "<strong>Bérelj elektromos kisautót</strong> játszóházba",
          description: "",
          image: "jatszohaz.jpg",
          actionButton: {
            moreDetails: "/szolgaltatasaink/berles-rendezvenyre/",
            bookAnAppointment: "#",
          },
        },
        {
          id: 3,
          title: "<strong>Bérelj elektromos kisautót</strong> rendezvényre",
          description:
            "Ön rendezvényszervező? Színesítse eseményeinek programját elektromos kisautózással, minden korosztály örömére!",
          image: "rendezveny.jpg",
          actionButton: {
            moreDetails: "/szolgaltatasaink/berles-rendezvenyre/",
            bookAnAppointment: "#",
          },
        },
        // {
        //   id: 4,
        //   title: "<strong>Bérelj elektromos kisautót</strong> otthonra",
        //   description: "Egy szürke hétköznapot, vagy egy átlagos hétvégét tenne színesebbé gyermeke számára?",
        //   image: "otthon.jpg",
        //   actionButton: {
        //     moreDetails: "/szolgaltatasaink/berles-otthonra/",
        //     bookAnAppointment: "#",
        //   },
        // },
        {
          id: 5,
          title: "<strong>Bérelj elektromos kisautót</strong> néhány körre",
          description:
            "Nem szeretné hazavinni az elektromos kisautót, mert nem fér el a lakásban, de szeretné, ha gyermeke kipróbálná valamelyik kis járgányt?",
          image: "kor.jpg",
          actionButton: {
            moreDetails: "/szolgaltatasaink/berles-nehany-percre-orara/",
            bookAnAppointment: "#",
          },
        },
      ],

      homeQuestions: [
        {
          id: 1,
          title: "Miért",
          answers: [
            "Fejleszti az éberséget és a figyelmet",
            "Ismerkedési lehetőséget nyújt a KRESZ-szel",
            "Javítja a koordinációs képességet és koncentrációt",
            "Fejleszti a térérzékelést és térlátást",
          ],
        },
        {
          id: 2,
          title: "Mivel",
          answers: [
            "Akkumulátoros elektromos kisautóval",
            "Szülői távirányítóval",
            "Teljes biztonságban",
            "Önálló közlekedéssel",
          ],
        },
        {
          id: 3,
          title: "Hogyan",
          answers: ["Néhány órára / napra", "Egész hétvégére / hétre", "Családi alkalmakra", "Rendezvényre"],
        },
      ],

      howToRentSteps: [
        {
          id: 1,
          title: "Igénylés",
          excerpt:
            "Jelzed felénk, hogy melyik kisautót, milyen időpontban, mennyi időre, milyen helyszínre szeretnéd kibérelni.",
          description:
            "Jelzed felénk, hogy melyik kisautót, milyen időpontban, mennyi időre, milyen helyszínre szeretnéd kibérelni.",
        },
        {
          id: 2,
          title: "Visszaigazolás",
          excerpt: "12 órán belül visszaigazoljuk a foglalásod, majd egyeztetjük az adatokat és a további részleteket.",
          description:
            "12 órán belül visszaigazoljuk írásban a foglalásod! Egyeztetjük az adatokat, az átvétel pontos helyét és időpontját.",
        },
        {
          id: 3,
          title: "Átadás",
          excerpt:
            "Kiszállítjuk a megadott címre, vagy üzletünkben átadjuk a választott kisautót a megbeszélt időpontban.",
          description:
            "Kiszállítjuk a megadott címre, vagy üzletünkben átadjuk a választott kisautót a megbeszélt időpontban. Több napos bérlés esetén a kezdőnapot megelőző este 16:00 és 19.00 óra között történik az átadás vagy a kiszállítás. A bérlés időtartama másnap ejféltől kezdődik. Mi átadjuk a járművet, aláírjuk a szerződést, Te átadod a kölcsönzési díjat és a kauciót (30 000 Ft / jármű).",
        },
        {
          id: 4,
          title: "Bemutatás",
          excerpt:
            "Bemutatjuk a kisautó működését, hogy teljes magabiztossággal élvezhesse a kis pilóta az élményautózást.",
          description:
            "Bemutatjuk a kisautó működését, hogy teljes magabiztossággal és balesetmentesen élvezhesse a kis pilóta az élményautózást. Megmutatjuk a távirányító használatát, és a töltés menetét.",
        },
        {
          id: 5,
          title: "Szórakozás",
          excerpt: "Kezdődhet az autózás és az önfeledt szórakozás!",
          description: "Kezdődhet az autózás és az önfeledt szórakozás!",
        },
        {
          id: 6,
          title: "Visszavétel",
          excerpt: "A kölcsönzés utolsó napján a jármű visszavétele a megadott helyszínen vagy üzletünkben.",
          description:
            "A kölcsönzés utolsó napján 16:00 és 19:00 között történik a jármű visszavétele az előre megbeszéltek szerint az általad megadott helyszínen vagy üzletünkben. Te átadod az autót, mi pedig egy rövid műszaki ellenőrzés után visszaadjuk a kauciót. (A teljes kaució csak abban az esetben jár vissza, ha hiánytalanul és sértetlenül kerül visszaadásra az autó. Sérülés, hiba esetén a javítási költség összege levonásra kerül a kaucióból.)",
        },
      ],

      services: [
        // {
        //   id: 1,
        //   slug: "otthonra",
        //   title: "Otthonra",
        //   image: "otthonra.jpg",
        //   excerpt: "Egy hétköznapot vagy hétvégét tennél színesebbé gyermeke számára?",
        //   mainContent: `
        //   <p>Otthonodban szeretnél örömet szerezni gyermekednek?
        //   Vagy autó vásárláson gondolkozol, de előtte kipróbálnád, mit szól a gyermeked hozzá?
        //   A KicsiKocsikat bérelheted akár több napra is.
        //   Fiús, lányos, unisex színeket találsz az autóparkban, így kedvetekre válogathattok.
        //   Az autókról egyesével is találsz leírást az oldalon.</p>
        //   <p>Eljöhetsz érte, de el is tudjuk vinni Neked!</p>`,
        //   subContent: `
        //   <p>Kaució több napos bérlés esetén: <strong>50 000 Ft</strong> / kicsikocsi</p>
        //
        //   <p><strong>Kiszállítás díja:</strong></p>
        //   <ul>
        //   <li>1-es zóna (IV, XIII, XV. kerületek): <strong>3 000 Ft</strong></li>
        //   <li>2-es zóna (I, II, III, V, VI, VII, VIII, X, XIV, XVI. kerületek, Dunakeszi, Fót): <strong>4 000 Ft</strong></li>
        //   <li>3-as zóna (IX, XI, XII, XVII, XVIII, XIX, XX, XXI, XXII, XXIII. kerületek): <strong>6 000 Ft</strong></li>
        //   </ul>
        //   <p>16 000 Ft feletti megrendelés esetén az 1-es és 2-es zónába a kiszállítás <strong>ingyenes</strong>! A 3-as zónába pedig kedvezményesen <strong>2 000 Ft</strong>!</p>
        //   <p>Fizetős parkolási övezetben minden esetben felszámítjuk a parkolás költségét.</p>
        //   <p><strong>Egyedi igények esetén kérje ajánlatunkat!</strong></p>
        //   <p><small>
        //     Az árak az oda-vissza szállítás költségét tartalmazzák!
        //     <br>A feltüntetett árak bruttó árak, 0% ÁFA-t tartalmaznak.
        //   </small></p>`,
        //   packages: [
        //     {
        //       id: 1,
        //       title: "Hétköznap, 2 napra",
        //       description: `<p>1 db elektromos jármű 2 napra</p>`,
        //       price: 15000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 2,
        //       title: "Hétköznap, 3 napra",
        //       description: `<p>1 db elektromos jármű 3 napra</p>`,
        //       price: 20000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 3,
        //       title: "Hétköznap, 4 napra",
        //       description: `<p>1 db elektromos jármű 4 napra</p>`,
        //       price: 25000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 4,
        //       title: "Hétköznap, 5 napra",
        //       description: `<p>1 db elektromos jármű 5 napra</p>`,
        //       price: 30000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 5,
        //       title: "Egész hétvégére",
        //       description: `<p>1 db elektromos jármű 2 napra<br>(péntek estétől vasárnap estig)</p>`,
        //       price: 20000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 6,
        //       title: "Hosszú hétvégére (3 nap)",
        //       description: `<p>1 db elektromos jármű 3 napra</p>`,
        //       price: 30000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 7,
        //       title: "Hosszú hétvégére (4 nap)",
        //       description: `<p>1 db elektromos jármű 4 napra</p>`,
        //       price: 40000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //     {
        //       id: 8,
        //       title: "Egész hétre (H-V)",
        //       description: `<p>1 db elektromos jármű 7 napra</p>`,
        //       price: 50000,
        //       price_is_hourly: false,
        //       deposit: 50000,
        //     },
        //   ],
        // },
        {
          id: 2,
          slug: "szuletesnapra",
          title: "Születésnapra",
          image: "szuletesnapra.jpg",
          excerpt: "Pici zsúr, vagy óriási party? Egy szülinapi száguldás négy keréken, az lesz az igazi!",
          mainContent: `
          <p>KicsiKocsis szülinapi bulit szeretnél gyermekednek?</p>
          <p>Rendeld házhoz vagy az általad választott helyszínre az elektromos kisautókat!
          Vagy olyan helyet keresel, ami zárt és nyitott is egyszerre és még sokan el is fértek az elektromos kisautókkal együtt?
          Kapcsolatban állunk erre a célra egy 110 nm-es fedett hellyel, ahol lehetőség van biztonságos, parkos környezetben kisautózni, ha az idő engedi.
          Hűtő biztosított, tortát és egyéb finomságokat is tudtok hozni, és azokat az ottani asztalokon fel is tudjátok tálalni. Bővebb információért keress telefonon minket!
          </p>`,
          subContent: ``,
          packages: [
            {
              id: 1,
              title: "Party csomag #1",
              description: `
                <p>3 db kisautó</p>
                <p>2 óra időtartamra</p>`,
              price: 40000,
              price_is_hourly: false,
              deposit: 0,
            },
            {
              id: 2,
              title: "Party csomag #2",
              description: `
                <p>6 db kisautó</p>
                <p>2 óra időtartamra</p>`,
              price: 80000,
              price_is_hourly: false,
              deposit: 0,
            },
          ],
        },
        {
          id: 3,
          slug: "rendezvenyre",
          title: "Rendezvényre",
          image: "rendezvenyre.jpg",
          excerpt: "Színesítsd eseményeid programját elektromos kisautózással!",
          mainContent: `
          <p>Bölcsődei, óvodai gyermeknapok?
          Alapítványi vagy önkormányzati rendezvények?
          Céges családi napok vagy rendezvények?
          Esetleg játszóházban alkalmi program?<p>
          <p>Válaszd elektromos kisautóinkat rendezvényed egyik nagyszerű programjaként!</p>
          <p><strong>Mit nyer a rendezvényszervező a kicsi kocsikkal?</strong></p>
          <ul>
            <li>A boldog gyerekek, és az elégedett szülők háláját.</li>
            <li>Egy egyszerűen lebonyolítható programot, hiszen csak a helyet kell biztosítani, minden más eszközt mi intézünk.</li>
            <li>Olyan programot, amellyel akár hagyományt is teremthet az időszakos rendezvényeken.</li>
            <li>Intézmények beilleszthetik a pedagógiai programba is, az alapvető közlekedési ismeretek tapasztalati úton történő elsajátítása érdekében.</li>
          </ul>
          <p><strong>A kicsi kocsik előnyei a gyerekek számára</strong></p>
          <ul>
            <li>Szabadban és zárt térben is kiváló program, ha van megfelelő nagyságú hely.</li>
            <li>Teljesen biztonságos, mégis az önálló vezetés élményét adja a gyerekeknek.</li>
            <li>Kb. 2 - 7 éves korig felejthetetlen kikapcsolódást nyújt. (Az autók 26 kg-ig terhelhetőek.)</li>
            <li>Az egészen kicsik száguldása, a távirányító segítségével teljes kontroll alatt tartható.</li>
            <li>Az valódi vezetés élményét nyújtja, hiszen lehet kormányozni, van motorhang, biztonsági öv, lámpák, duda, rádió és minden egyéb, ami egy igazi autóban is.</li>
            <li>Játékosan tanít a közlekedési szabályokra, és azok betartására.</li>
            <li>Fejleszti a koordinációs, és koncentrációs képességet, sikeresen alkalmazható a szem-kéz, illetve kéz-láb koordináció fejlesztésére, és tökéletesítésére.</li>
          </ul>`,
          subContent: `
          <p><strong>Technikai igények:</strong></p>
          <ul>
            <li>min. 100 nm sík vagy aszfaltozott terep (zárt hely esetén min. 50 nm terület)</li>
            <li>220 V</li>
            <li>asztal + 2 szék</li>
            <li>árnyékos hely, ha megoldható</li>
          </ul>
          `,
          packages: [
            {
              id: 1,
              title: "Rendezvény csomag óránként",
              description: `
              <p>2-6 db elektromos kisautó<br><small>(2-7 éveseknek, max 26 kg-ig)</small></p>
              <p>KRESZ tábla</p>
              <p>Bóják</p>
              <p>Animátor(ok)</p>
              <p>Plusz akkumulátorok a folyamatos működéshez</p>`,
              price: 35000,
              price_is_hourly: false,
              price_is_hourly_from: true,
              deposit: 0,
            },
          ],
        },
        {
          id: 4,
          slug: "nehany-percre-orara",
          title: "Néhány percre / órára",
          image: "nehany-percre-orara.jpg",
          excerpt: "Próbálja ki gyermeke valamelyik elektromos kisautót a helyszínen!",
          mainContent: `
          <p>Egy rövidke délutáni programot szeretnél?
          Vagy egy hétvégi családi sétát elektromos kisautózással egybekötve?
          Várunk üzletünk körül parkos, fás környezetben, közúttól házakkal elhatárolt biztonságos területen.
          Bérelhettek 30 vagy 60 percre is, egy vagy akár több elektromos kisautót egyszerre.
          Telefonon jelezd mikor és hányan szeretnétek jönni, hogy biztosan legyen szabad autó vagy ott legyen az éppen kiszemelt aktuális kedvenc.</p>`,
          subContent: `
          <p>A perc alapú helyszíni élményautózáshoz előzetes időpont egyeztetés szükséges!</p>
          <p>Perc alapú élményautózásra kizárólag személyes átvétel esetén van lehetőség.</p>`,
          packages: [
            {
              id: 1,
              title: "15 perc",
              description: ``,
              price: 1000,
              price_is_hourly: false,
              deposit: 0,
            },
            {
              id: 2,
              title: "30 perc",
              description: ``,
              price: 2000,
              price_is_hourly: false,
              deposit: 0,
            },
            {
              id: 3,
              title: "60 perc",
              description: ``,
              price: 4000,
              price_is_hourly: false,
              deposit: 0,
            },
          ],
        },
      ],

      carEquipmentsNames: {
        remoteControl: "Szülői távirányító – vészfék funkcióval",
        gearButton: "Sebességváltó gomb (3 sebesség előre, 1 sebesség hátra)",
        a: "Gázpedál a lábtartóban",
        b: "Előre és hátramenet",
        c: "Világító kék, piros lámpák",
        d: "Nyitható tároló rekeszek",
        e: "Kulcsos indítás",
        f: "Bukócsövek",
        seatBelt3Point: "3 pontos állítható biztonsági öv",
        integratedPedal: "Integrált pedál- Elektromos fékezés a gázpedál felengedése után",
        startButton: "Gombos indulás",
        chargeLevelDisplay: "Töltöttségi szint kijelzése",
        digitalBatteryChargeMeter: "Digitális akkumulátor töltöttség mérő",
        doorsOpen: "Nyitható ajtók",
        horn: "Kürt",
        ledLights: "LED-es első és hátsó lámpák, amik kapcsolhatóak",
        dashboardLights: "Műszerfal világítás",
        leatherSeat: "Bőrhatású kárpitozott ülés",
        logos: "Eredeti emblémák a kormányon, a motorházfedélen és a csomagtartón",
        chromeRim: "Krómhatású felni",
        volumeControl: "Hangerőszabályzás",
        soundEffects: "Hangeffektusok",
        usbInput: "USB bemenet",
        radio: "Több dallam lejátszása",
        mp3: "MP3",
        microSd: "Micro SD",
      },

      carSpecificationsNames: {
        maxCapacity: "Max terhelhetőség",
        age: "Korosztály",
        speed: "Sebesség",
        engine: "Meghajtás",
        battery: "Akkumulátor",
        charger: "Töltő",
        chargingTime: "Töltési idő",
        drivingTime: "Vezetési idő",
        weight: "Súly",
        length: "Hosszúság",
        width: "Szélesség",
        height: "Magasság",
        wheel: "Kerék átmérője",
        abdominalHeight: "Has magasság",
        seatWidth: "Ülés szélessége",
      },

      carSpecificationsUnits: {
        maxCapacity: "kg",
        age: "év",
        speed: "km/h",
        engine: "",
        battery: "",
        charger: "",
        chargingTime: "óra",
        drivingTime: "óra (gyermek súlyától és a terepviszonyoktól függ)",
        weight: "kg",
        length: "cm",
        width: "cm",
        height: "cm",
        wheel: "cm",
        abdominalHeight: "cm",
        seatWidth: "cm",
      },

      cars: [
        {
          id: 1,
          slug: "bmw-x6m-piros",
          title: "BMW X6M",
          color: "Piros",
          image: "bmw-x6m-piros.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 2,
          slug: "bmw-x6m-fekete",
          title: "BMW X6M",
          color: "Fekete",
          image: "bmw-x6m-fekete.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 3,
          slug: "bmw-x6m-2szemelyes-fekete",
          title: "BMW X6M - 2 személyes",
          color: "Fekete",
          image: "bmw-x6m-2szemelyes-fekete.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott 2 személyes elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 35,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 3,
          slug: "mercedes-glc63s-feher",
          title: "Mercedes GLC63s",
          color: "Fehér",
          image: "mercedes-glc63s-feher.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 4,
          slug: "mercedes-gle63-fekete",
          title: "Mercedes GLE63",
          color: "Fekete",
          image: "mercedes-gle63-fekete.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 5,
          slug: "mercedes-sl65-rozsaszin",
          title: "Mercedes SL65",
          color: "Rózsaszín",
          image: "mercedes-sl65-rozsaszin.jpg",
          excerpt:
            "A Mercedes-Benz S63 AMG kicsinyített mása, sok extrával ellátott járgány világító lámpákkal, MP3 csatlakozóval és távirányítóval.",
          description: `
            <p>A Mercedes-Benz S63 AMG kicsinyített mása, sok extrával ellátott járgány világító lámpákkal, MP3 csatlakozóval és távirányítóval.</p>
            <p>Sok, a nagy autóban is megtalálható felszereltséggel is bír, úgy mint a bőr hatású kárpit, az üléseken a biztonsági öv. A média panel segítségével zene és akár mese lejátszására is lehetőség van. Több típus FM rádióval, USB csatlakozóval, MP3 és Bluetooth lejátszóval felszerelt. Indítókulccsal felszereltek.</p>
            <p>Nyithatóak az ajtók, így a gyermeke könnyen és kényelmesen tud ki és beszállni.</p>
            <p>Szülői távirányító használatával nem csak a menetirányt és a sebességet szabályozhatjuk, hanem korrigálhatjuk, leállíthatjuk az autót a stop gomb megnyomásával.</p>
            <p><strong>A kisautót kb 125 cm magasságig és 30 kg-ig ajánljuk.</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "digitalBatteryChargeMeter",
            "doorsOpen",
            "horn",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "volumeControl",
            "soundEffects",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 3,
              max: 6,
            },
            engine: "2 x 12V / 45W",
            battery: "12V / 7Ah",
            charger: "220V / 12V",
            chargingTime: {
              min: 8,
              max: 12,
            },
            drivingTime: {
              min: 1,
              max: 2,
            },
            weight: 18,
            length: 120,
            width: 69,
            height: 50,
            wheel: 25,
            abdominalHeight: null,
            seatWidth: null,
          },
        },
        {
          id: 6,
          slug: "mercedes-sl65-feher",
          title: "Mercedes SL65",
          color: "Fehér",
          image: "mercedes-sl65-feher.jpg",
          excerpt:
            "A Mercedes-Benz S63 AMG kicsinyített mása, sok extrával ellátott járgány világító lámpákkal, MP3 csatlakozóval és távirányítóval.",
          description: `
            <p>A Mercedes-Benz S63 AMG kicsinyített mása, sok extrával ellátott járgány világító lámpákkal, MP3 csatlakozóval és távirányítóval.</p>
            <p>Sok, a nagy autóban is megtalálható felszereltséggel is bír, úgy mint a bőr hatású kárpit, az üléseken a biztonsági öv. A média panel segítségével zene és akár mese lejátszására is lehetőség van. Több típus FM rádióval, USB csatlakozóval, MP3 és Bluetooth lejátszóval felszerelt. Indítókulccsal felszereltek.</p>
            <p>Nyithatóak az ajtók, így a gyermeke könnyen és kényelmesen tud ki és beszállni.</p>
            <p>Szülői távirányító használatával nem csak a menetirányt és a sebességet szabályozhatjuk, hanem korrigálhatjuk, leállíthatjuk az autót a stop gomb megnyomásával.</p>
            <p><strong>A kisautót kb 125 cm magasságig és 30 kg-ig ajánljuk.</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "digitalBatteryChargeMeter",
            "doorsOpen",
            "horn",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "volumeControl",
            "soundEffects",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 3,
              max: 6,
            },
            engine: "2 x 12V / 45W",
            battery: "12V / 7Ah",
            charger: "220V / 12V",
            chargingTime: {
              min: 8,
              max: 12,
            },
            drivingTime: {
              min: 1,
              max: 2,
            },
            weight: 18,
            length: 120,
            width: 69,
            height: 50,
            wheel: 25,
            abdominalHeight: null,
            seatWidth: null,
          },
        },
        {
          id: 7,
          slug: "ford-focus-rs-piros",
          title: "Ford Focus RS",
          color: "Piros",
          image: "ford-focus-rs-piros.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 8,
          slug: "audi-rs5-piros",
          title: "Audi RS 5",
          color: "Piros",
          image: "audi-rs5-piros.jpg",
          excerpt:
            "Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható.",
          description: `
            <p>Eredeti liszensz alapján gyártott elektromos kisautó. Nagyon sok extrával ellátott járgány nyitható ajtókkal, világító lámpákkal és lassított indulással. Szülői távirányítóval is irányítható a kisautó, amin 3 sebességfokozat választható valamint az irány is koordinálható (3 sebesség előre, 1 sebességfokozat hátra).</p>
            <p>Távirányítón vészfék funkció is van. A vészfék gomb megnyomásával azonnal átvehető az irányítás a kisautó felett, ismételt lenyomásával akár menet közben újra visszaadható a kis sofőrnek az irányítás.</p>
            <p>Egyéb más játék távirányítók nem zavarják meg működtetését, több járgányt is lehet egyidejűleg működtetni. Kisebb gyermekek is biztonsággal utazhatnak benne.</p>
            <p><strong>Kb. 120 cm magasságig és 30 kg-ig ajánljuk ezt az autót!</strong></p>
          `,
          equipments: [
            "remoteControl",
            "gearButton",
            "seatBelt3Point",
            "integratedPedal",
            "startButton",
            "chargeLevelDisplay",
            "doorsOpen",
            "ledLights",
            "dashboardLights",
            "leatherSeat",
            "logos",
            "chromeRim",
            "usbInput",
            "radio",
            "mp3",
            "microSd",
          ],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 2,
              max: 7,
            },
            speed: {
              min: 2,
              max: 8,
            },
            engine: null,
            battery: null,
            charger: "220V / 12V",
            chargingTime: {
              min: 5,
              max: 12,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 20,
            length: 113,
            width: 75,
            height: 60,
            wheel: 26,
            abdominalHeight: 12,
            seatWidth: 40,
          },
        },
        {
          id: 10,
          slug: "bmw-rendor-motor-piros",
          title: "BMW Rendőr motor",
          color: "Piros",
          image: "bmw-rendor-motor-piros.jpg",
          excerpt:
            "Egy rendőrmotor a legtöbb kisfiú álma nekik ajánljuk ezt a gyönyörűen kidolgozott elektromos kismotort, leszerelhető segédkerekekkel.",
          description:
            "Egy rendőrmotor a legtöbb kisfiú álma nekik ajánljuk ezt a gyönyörűen kidolgozott elektromos kismotort, leszerelhető segédkerekekkel.",
          equipments: ["integratedPedal", "horn", "ledLights", "soundEffects", "a", "b", "c", "d", "e", "f"],
          specifications: {
            maxCapacity: 26,
            age: {
              min: 4,
              max: 7,
            },
            speed: {
              min: 3,
              max: 6,
            },
            engine: "1 x 12V 15000 RPM",
            battery: null,
            charger: null,
            chargingTime: {
              min: 8,
              max: 10,
            },
            drivingTime: {
              min: 0.5,
              max: 2,
            },
            weight: 18,
            length: 76,
            width: 123,
            height: 54,
            wheel: null,
            abdominalHeight: null,
            seatWidth: null,
          },
        },
        {
          id: 11,
          slug: "bmw-kis-motor-fekete",
          title: "BMW Kis motor",
          color: "Fekete",
          image: "bmw-kis-motor-fekete.jpg",
          excerpt: "",
          description: "",
          equipments: ["a"],
          specifications: {
            maxCapacity: 20,
            age: {
              min: 1,
              max: 3,
            },
            speed: {
              min: 1,
              max: 3,
            },
            engine: null,
            battery: null,
            charger: null,
            chargingTime: {
              min: null,
              max: null,
            },
            drivingTime: {
              min: null,
              max: null,
            },
            weight: null,
            length: null,
            width: null,
            height: null,
            wheel: null,
            abdominalHeight: null,
            seatWidth: null,
          },
        },
      ],

      posts: [
        {
          id: 21,
          slug: "gyermeknapi-meglepetes-a-komaromi-ovisoknak",
          title: "Gyermeknapi meglepetés a komáromi ovisoknak",
          excerpt: `
          <p>Amikor májusban csörgött a telefonunk, egy nagyon kedves hölgy invitált minket egy számunkra nagyon kedves rendezvényre. A vezető óvónéni kérte fel az elektromos kisautókat, hogy legyenek meglepetés program az ovisok számára szervezett gyermeknapi rendezvényükön. Mondanunk sem kell, volt nagy öröm a gyerekek arcán. Fantasztikus délelőttöt töltöttünk a Csillag ovi gyermekeivel és dolgozóival.</p>
          `,
          content: `
          <p>Amikor májusban csörgött a telefonunk, egy nagyon kedves hölgy invitált minket egy számunkra nagyon kedves rendezvényre. A vezető óvónéni kérte fel az elektromos kisautókat, hogy legyenek meglepetés program az ovisok számára szervezett gyermeknapi rendezvényükön. Mondanunk sem kell, volt nagy öröm a gyerekek arcán. Fantasztikus délelőttöt töltöttünk a Csillag ovi gyermekeivel és dolgozóival.</p>
          <p>Köszönjük a meghívást!</p>
          <p>Jövőre ugyanitt! :)</p>
          <img src="../images/blog/gyermeknapi-meglepetes-a-komaromi-ovisoknak/02.jpg" alt="Gyermeknapi meglepetés a komáromi ovisoknak" />
          `,
          image: "gyermeknapi-meglepetes-a-komaromi-ovisoknak.jpg",
          date: {
            year: "2024",
            month: "05",
            day: "31",
          },
          moreDisabled: false,
          headerDisabled: false,
        },
        {
          id: 20,
          slug: "soroksari-ujtelepi-szabadidopark-megnyitoja",
          title: "A soroksári Újtelepi szabadidőpark és játszótér megnyitóján jártunk",
          excerpt: `
            <p>Májusban az a megtiszteltetés érte az elektromos kisautóinkat, hogy Budapest Főváros XXIII. kerület Soroksári Önkormányzat Újtelepi szabadidőpark és játszótér megnyitóján ők szállíthatták elsőként a játszótérre érkező gyerek hadat a szuper KRESZ pályán.</p>
          `,
          content: `
            <p>Májusban az a megtiszteltetés érte az elektromos kisautóinkat, hogy Budapest Főváros XXIII. kerület Soroksári Önkormányzat Újtelepi szabadidőpark és játszótér megnyitóján ők szállíthatták elsőként a játszótérre érkező gyerek hadat a szuper KRESZ pályán.</p>
            <p>Képzeljétek! Még körforgalom és működő jelzőlámpa is a rendelkezésetekre áll akár elektromos kisautóval, akár bicajjal, akár rollerrel érkeztek.
            Na és a kerítéssel védett, zárt játszótér hatalmas parkos területen található csuda jó játékokkal, homokozóval és kosárlabda pályával.</p>
            <p>Keressétek fel bátran Ti is!</p>

            <img src="../images/blog/soroksari-ujtelepi-szabadidopark-megnyito/02.jpg" alt="Soroksári Újtelepi szabadidőpark és játszótér megnyitója" />
          `,
          image: "soroksari-ujtelepi-szabadidopark-megnyito.jpg",
          date: {
            year: "2024",
            month: "05",
            day: "28",
          },
          moreDisabled: false,
          headerDisabled: false,
        },
        {
          id: 19,
          slug: "",
          title: "Megérkezett a várva várt 2 személyes autó",
          excerpt: `
          <p>Nagyon sokan érdeklődtetek, mikor lesz végre 2 személyes autónk, de olyan legyen, ami egy kicsit nagyobb gyermekeket is elbír. Kérésetek számunkra parancs, így pár napja forgalomba is állt a legújabb BMW X6-os, ami már 35 kg-ig terhelhető.</p>
          <p>Figyelem: ez az autó csak a helyszínen bérelhető!</p>
          `,
          content: `
          `,
          image: "2-szemelyes-elektromos-kisauto.jpg",
          date: {
            year: "2024",
            month: "05",
            day: "01",
          },
          moreDisabled: true,
          headerDisabled: true,
        },
        {
          id: 18,
          slug: "",
          title: "Falunap Káván",
          excerpt: `
            <p>Egy nagyon helyes kis faluba hívtak minket Falunapra.</p>
            <p>A kávai lakosok és legfőképp a gyerekek nagyon kedvesek, jó szívvel gondolunk vissza rájuk még most is.</p>
          `,
          content: ``,
          image: "falunap-kavan.jpg",
          date: {
            year: "",
            month: "",
            day: "",
          },
          moreDisabled: true,
          headerDisabled: true,
        },
        {
          id: 17,
          slug: "",
          title: "Nyitvatartásunk bejelentkezés alapján történik",
          excerpt: `
          <p>Nyitvatartásunk bejelentkezés alapján történik, általában 10 percen belül ott tudunk lenni.</p>
          <p>Hívj minket a 06307488928-as számon hétfőtől vasárnapig 9:00 - 18:00 óra között.</p>
          `,
          content: ``,
          image: "nyitvatartas-2024.png",
          date: {
            year: "2024",
            month: "03",
            day: "10",
          },
          moreDisabled: true,
          headerDisabled: true,
        },
        {
          id: 16,
          slug: "kozlekedj-okosan-elektromos-kisautoval-tabor-2024",
          title: "Közlekedj okosan, elektromos kisautóval tábor: Július 1-5.",
          excerpt: `
            <p>Elindult a jelentkezés a Kisautós nyári KRESZ táborba! Biztosítsd a helyed 20% befizetésével, mivel a tábor létszáma idén maximum 10 fő.</p>
            <p>A helyek gyorsan szoktak telni, ezért a jelentkezés/befizetés sorrendje számít!</p>
            <p>A kor és súlyhatár idén is 4-7 év és max. 28 kg!</p>
          `,
          content: `
          <p>Elindult a jelentkezés a Kisautós nyári KRESZ táborba. Biztosítsd a helyed 20% befizetésével, mivel a tábor létszáma idén maximum 10 fő. A helyek gyorsan szoktak telni, ezért a jelentkezés/befizetés sorrendje számít. </p>
          <p>Testvéreknek 2-2000 Ft kedvezményt biztosítunk.</p>
          <p>A kor és súlyhatár idén is 4-7 év és max. 28 kg!</p>
          <div class="row">
            <div class="col-md-5">
              <h2>️A jelentkezés menete:</h2>
              <ol>
              <li>Írj üzenetet az info@gyerekautokolcsonzo.hu email címre, ahol adj meg minden szükséges adatot:
                <ul>
                  <li>gyermek neve,</li>
                  <li>életkora,</li>
                  <li>testsúlya,</li>
                  <li>esetleges ételallergia,</li>
                  <li>számlázási név és cím,</li>
                  <li>teljes összeg vagy 20% előleg kerül első körben befizetésre</li>
                </ul>
              </li>
              <li>Várd a díjbekérő számlát</li>
              <li>Fizesd be az összeget és várd a visszaigazolást a regisztráció véglegesítéséről</li>
              </ol>
              <div class="row gap-4">
                <div class="col-md-12">
                  <img src="../images/blog/kozlekedj-okosan-elektromos-kisautoval-tabor-2024/02.jpg" alt="Közlekedj okosan, elektromos kisautóval tábor: Július 1-5." />
                </div>
                <div class="col-md-12">
                  <img src="../images/blog/kozlekedj-okosan-elektromos-kisautoval-tabor-2024/03.jpg" alt="Közlekedj okosan, elektromos kisautóval tábor: Július 1-5." />
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <img src="../images/blog/kozlekedj-okosan-elektromos-kisautoval-tabor-2024/01.jpg" alt="Közlekedj okosan, elektromos kisautóval tábor: Július 1-5." />
            </div>
          </div>
          `,
          image: "kozlekedj-okosan-elektromos-kisautoval-tabor-2024.jpg",
          date: {
            year: "2024",
            month: "03",
            day: "03",
          },
          moreDisabled: false,
          headerDisabled: true,
        },
        {
          id: 15,
          slug: "2-evesek-lettunk",
          title: "2 évesek lettünk!",
          excerpt: `
            <p>Ismét eltelt egy újabb év, 2 évesek lettünk! Köszönjük a bizalmatokat és, hogy ennyien szerettek minket! Idén is várunk Titeket szeretettel, immár 2. éve változatlan árakkal.</p>
            <p>Állandó nyitvatartásunk még nincs az időjárás változékonysága miatt, de ahogy eddig is, telefonos egyeztetés után akár már negyed órán belül ott vagyunk.
            <br>Hétfőtől vasárnapig: <a href="tel:06307488928">+3630 748 8928</a>
            </p>
          `,
          content: `
            <p>Ismét eltelt egy újabb év, 2 évesek lettünk!</p>
            <p>Köszönjük a bizalmatokat és, hogy ennyien szerettek minket!</p>
            <p>Idén is várunk Titeket szeretettel, immár 2. éve változatlan árakkal.</p>
            <p>Állandó nyitvatartásunk még nincs az időjárás változékonysága miatt, de ahogy eddig is, telefonos egyeztetés után akár már negyed órán belül ott vagyunk.</p>
            <p>Hétfőtől vasárnapig: <a href="tel:06307488928">+3630 748 8928</a></p>
          `,
          image: "2-evesek-lettunk.png",
          date: {
            year: "2024",
            month: "03",
            day: "01",
          },
          moreDisabled: true,
        },
        {
          id: 14,
          slug: "tavasz-varo-nyitvatartas",
          title: "Tavaszváró nyitvatartás",
          excerpt: `
            Kezdődnek a jó idők, állandó nyitvatartásunk még nincs, de bátran hívjatok, ha erre jártok, vagy csak hozzánk jönnétek. 10 perc és jövünk!
            <br>
            \nHétfőtől vasárnapig: +3630 748 8928
          `,
          content: `
            <p>Kezdődnek a jó idők, állandó nyitvatartásunk még nincs, de bátran hívjatok, ha erre jártok, vagy csak hozzánk jönnétek. 10 perc és jövünk!</p>
            <p>Hétfőtől vasárnapig: <a href="tel:06307488928">+3630 748 8928</a></p>
          `,
          image: "tavasz-varo-nyitvatartas.jpg",
          date: {
            year: "2024",
            month: "02",
            day: "24",
          },
          moreDisabled: true,
        },
        {
          id: 13,
          slug: "autoszerelo-muhely",
          title: "Készülünk az ide szezonra!",
          excerpt: `
            Ugyan még leginkább egy autószerelő műhelyre hasonlítunk, de ez is biztos jele már annak, hogy hamarosan indul a jó idő, és vele együtt újra az élményvezetések időszaka is.
            2024-ben is várunk minden régi és új kis sofőrünket szeretettel és továbbra is VÁLTOZATLAN árakkal!
          `,
          content: `
            Ugyan még leginkább egy autószerelő műhelyre hasonlítunk, de ez is biztos jele már annak, hogy hamarosan indul a jó idő, és vele együtt újra az élményvezetések időszaka is.
            2024-ben is várunk minden régi és új kis sofőrünket szeretettel és továbbra is VÁLTOZATLAN árakkal!
            Ezen kívül idén nyáron is lesz TÁBOR, az időpont hamarosan kikerül, figyeljétek és osszátok oldalunkat. A helyek idén is csak korlátozott számban lesznek elérhetőek!
          `,
          image: "autoszerelo-muhely.jpg",
          date: {
            year: "2024",
            month: "02",
            day: "21",
          },
          moreDisabled: true,
        },
        {
          id: 12,
          slug: "polus-center-jegpalyan-is-2023-december",
          title: "Január 9-ig a Pólus Center jégpálya területén is megtaláltok minket!",
          excerpt: `
          Hétvégén 10-19 óra között, hétköznap 16-19 óra között leszünk nyitva (Kivétel: Karácsony és Szilveszter).
          Pólus árak: 2000 Ft / 30 perc. Pontgyűjtővel rendelkező kis sofőreinkre ugyan azok az árak vonatkoznak, mint az üzletben!
          `,
          content: `
            <p>Hétvégén 10-19 óra között, hétköznap 16-19 óra között leszünk nyitva.
            Karácsonykor és Szilveszter/Újév napján igazodunk a Pólus nyitvatartásához mi is.
            Január 2-8 között pedig minden nap itt leszünk 10-19 óra között.</p>
            <p>Pólus árak: 2000 Ft / 30 perc</p>
            <p>Pontgyűjtővel rendelkező kis sofőreinkre ugyan azok az árak vonatkoznak, mint az üzletben!</p>
            <p>Az autók továbbra is csak max. 28 kg-ig terhelhetőek.</p>
            <div class="row">
              <div class="col-md-6">
                <img src="../images/blog/polus-center-jegpalyan-is-2023-december/01.jpg" alt="Január 9-ig a Pólus Center jégpálya területén is megtaláltok minket!" />
              </div>
              <div class="col-md-6">
                <img src="../images/blog/polus-center-jegpalyan-is-2023-december/02.jpg" alt="Január 9-ig a Pólus Center jégpálya területén is megtaláltok minket!" />
              </div>
            </div>
          `,
          image: "polus-center-jegpalyan-is-2023-december.jpg",
          date: {
            year: "2023",
            month: "12",
            day: "09",
          },
          moreDisabled: false,
        },
        {
          id: 11,
          slug: "nyitvatartas-2023-08-07-es-2023-08-11-kozott",
          title: "Nyitvatartás 2023.08.07-11. között",
          excerpt:
            "A héten táboroztatás miatt így leszünk nyitva:" +
            "<br><strong>Hétfő:</strong> 10:00 - 12:30 és 15:30 - 19:30" +
            "<br><strong>Kedd:</strong> telefonos bejelentkezés alapján" +
            "<br><strong>Szerda:</strong> 10:00 - 12:30 és 15:30 - 19:30" +
            "<br><strong>Csütörtök:</strong> telefonos bejelentkezés alapján" +
            "<br><strong>Péntek:</strong> 10:00 - 12:30 és 15:30 - 19:30" +
            '<br><br>Ügyeleti telefonszám a hétre: <strong><a href="tel:+36302834739">+36 30 283 4739</a></strong>',
          content: `
          <p>A héten táboroztatás miatt így leszünk nyitva:</p>
          <ul>
            <li><strong>Hétfő:</strong> 10:00 - 12:30 és 15:30 - 19:30</li>
            <li><strong>Kedd:</strong> telefonos bejelentkezés alapján</li>
            <li><strong>Szerda:</strong> 10:00 - 12:30 és 15:30 - 19:30</li>
            <li><strong>Csütörtök:</strong> telefonos bejelentkezés alapján</li>
            <li><strong>Péntek:</strong> 10:00 - 12:30 és 15:30 - 19:30</li>
          </ul>
          <p>Ügyeleti telefonszám a hétre: <strong><a href="tel:+36302834739">+36 30 283 4739</a></strong></p>
          `,
          image: "blog-logo.jpg",
          date: {
            year: "2023",
            month: "08",
            day: "06",
          },
          moreDisabled: true,
        },
        {
          id: 10,
          slug: "ii-csaladi-kresz-nap",
          title: "II. Családi KRESZ nap",
          excerpt:
            "Egyre jobb idő van, ezért fontos, hogy a kisebbeknek és a picit nagyobbaknak is felhívjuk a figyelmét a közlekedésbiztonságra. Ott leszünk mi is 13-18 óra között a II. családi KRESZ napon! Gyertek!",
          content: `
          <p>Eljött az idő, hogy a tavalyi sikerek után újra egy nagyon jó és hasznos programot hozzunk el nektek...</p>
          <h2>II. Családi KRESZ nap: "Előgyereknap" a közlekedésbiztonság szellemében!</h2>
          <p>Egyre jobb idő van, kerülnek elő bringák, rollerek és egyéb csoda járművek ezért fontos, hogy a kisebbeknek és a picit nagyobbaknak is felhívjuk a figyelmét a közlekedésbiztonságra, persze játékos formában.</p>
          <p>Gyertek és töltsünk el együtt egy vidám napot!</p>
          <h3>Programok</h3>
          <ul>
            <li><strong>08:00 - 13:00:</strong> Barátságos Kőrösi Forgatag vásár</li>
            <li><strong>10:00 - 13:00:</strong> Pedálos GOKART szabadedzés</li>
            <li><strong>10:00 - 18:00:</strong> Művésztelep</li>
            <li><strong>10:00 - 18:00:</strong> LIME Roller KRESZ Challenge</li>
            <li><strong>13:00 - 14:00:</strong> Nyitó ünnepély</li>
            <li><strong>13:00 - 17:30:</strong> Közlekedésbiztonsági vetélkedő (ügyességi játékok, pedálos gokart futam a makettvárosban, interaktív játékok)<br>Sikeres teljesítésért érmet és oklevelet kapnak a gyerkőcök</li>
            <li><strong>13:00 - 18:00:</strong> Arcfestő, Gólyalábas show, Lufihajtogató</li>
            <li><strong>14:00 - 16:00:</strong> Rendőrségi bemutató, Tűzoltó előadás és bemutató, Katasztrófavédelmi bemutató, Mentőszolgálat előadás</li>
            <li><strong>16:00 - 16:30:</strong> Origo Dance - Junior hip hop streetdance</li>
            <li><strong>16:30 - 16:50:</strong> Csonka András minikoncert</li>
            <li><strong>18:00:</strong> Záróbeszéd</li>
          </ul>
          <br>
          <ul>
            <li>12:00 - 18:00: Ingyenes városnéző kisvasút</li>
          </ul>
          <p><strong>A rendezvény díjmentesen látogatható!</strong></p>
          `,
          image: "ii-csaladi-kresz-nap.jpg",
          date: {
            year: "2023",
            month: "05",
            day: "12",
          },
        },
        {
          id: 9,
          slug: "probald-ki-a-legujabb-kisautonkat",
          title: "Próbáld ki a legújabb kisautónkat és élvezd az élményt, amit a Gyerekautó kölcsönző nyújt Nektek!",
          excerpt:
            "Nagy örömmel mutatjuk be legújabb elektromos kisautónkat, mely szintén egy igazi típus kicsinyített mása. Modern, sportos kialakítású és élethű. Könnyen kezelhető, és a sebessége is szabályozható. Van hozzá szülői távirányító és biztonsági öv is!",
          content: `
          <p>Nagy örömmel mutatjuk be legújabb elektromos kisautónkat, mely szintén egy igazi típus kicsinyített mása. Modern, sportos kialakítású és élethű. Könnyen kezelhető, és a sebessége is szabályozható. Van hozzá szülői távirányító és biztonsági öv is, így akár a legfiatalabbak is élvezhetik a vezetést.</p>
          <p>Egy újabb választási lehetőség azoknak a gyermekeknek, akik imádják az elektromos autók kalandos világát.</p>
          <p>Látogass el hozzánk, és béreld ki gyermekednek az új kisautót is. Vagy, ha eddig még nem jártál nálunk, kezd ezzel az ismerkedést.</p>
          <p>Garantáljuk, hogy gyermeked sosem felejti el a kalandot, amit az elektromos kisautóinkkal élhetnek át! Ha még emlékezetesebbé szeretnéd tenni az élményt, bátran hívjátok el a barátaitokat is! Kisebb csoport érkezésénél azonban érdemes előtte bejelentkezni és foglalni, hogy biztosan minden gyermek számára legyen bent kisautó.</p>
          <div class="row">
            <div class="col-sm-4">
              <picture>
                <img src="../images/blog/probald-ki-a-legujabb-kisautonkat/01.jpg" alt="">
              </picture>
            </div>
            <div class="col-sm-4">
              <picture>
                <img src="../images/blog/probald-ki-a-legujabb-kisautonkat/02.jpg" alt="">
              </picture>
            </div>
            <div class="col-sm-4">
              <picture>
                <img src="../images/blog/probald-ki-a-legujabb-kisautonkat/03.jpg" alt="">
              </picture>
            </div>
          </div>
          <p></p>
          <p>Jelentkezz be még ma!</p>
          <p>Szeretettel várunk Titeket!</p>
          `,
          image: "probald-ki-a-legujabb-kisautonkat.jpg",
          date: {
            year: "2023",
            month: "04",
            day: "20",
          },
        },
        {
          id: 8,
          slug: "husveti-nyitvatartas",
          title: "Húsvéti nyitvatartás",
          excerpt:
            "A tavaszi szünetben és Húsvétkor is nyitva leszünk! Érdemes legalább előtte nap telefonon autót foglalni!",
          content: `
          <p>A tavaszi szünetben és Húsvétkor is nyitva leszünk, de érdemes legalább előtte nap telefonon autót foglalni<sup>*</sup>, hogy biztosan legyen amikor jöttök.</p>
          <p>Nézzetek szét weboldalunkon, milyen autók közül lehet választani, de a helyszínre érkezve természetesen van lehetősége a kis sofőrnek változtatni az elképzelésén a szabad autók függvényében.</p>
          <p><i><sup>*</sup>A foglalás nem kötelez semmire, akár aznap reggel is lemondható.</i></p>
          <p>Szeretettel várunk Titeket!</p>
          `,
          image: "husveti-nyitvatartas.jpg",
          date: {
            year: "2023",
            month: "03",
            day: "28",
          },
        },
        {
          id: 7,
          slug: "hogyan-keszitsd-fel-gyermeked-a-kicsikocsikazasra",
          title: "Hogyan készítsd fel gyermeked a KicsiKocsikázásra?",
          excerpt: "Cikkünkben adunk négy jótanácsot a nyugodt szórakozáshoz.",
          content: `
          <ol>
            <li>Keresd fel facebook vagy weboldalunkat gyermekeddel és nézzétek meg, milyen járművek közül lehet választani. A kisebbeknek a távirányítóval is rendelkező autókat ajánljuk. Így már lesz elképzelése a gyermeknek a kínálatról. Persze az üzletbe érkezve ez azért még változhat. :)</li>
            <li>Mondd el előre gyermekednek, hogy a kisautót bérelni fogjátok, és beszéljétek meg, mit jelent a bérlés szó. Ha előre tudja, hogy az autót – számára néha- rövid idő után vissza kell vinni, ott kell hagyni, de máskor is tud jönni és akár másik autóval is mehet, könnyebben elfogadja, hogy a szeretett játékát nem viheti haza.</li>
            <li>Érdemes az idő letelte előtt ismét jelezni számára, hogy hamarosan visszavisszük, és jövünk máskor is.</li>
            <li>Amiről még érdemes beszélni, hogy az autók mozgásához tér kell, tehát az üzletben nem célszerű beleülni. Beindítás után egy rövid gázra lépés is ütközéshez, esetleg töréshez vezet. Sajnos az ilyenkor keletkezett kár már a szülőt, hozzátartozót terheli.</li>
          </ol>
          <p>Van, hogy bármennyire is igyekszünk felkészíteni a gyermeket, nehéz az elválás, mert nagy élmény a gyerekek számára a vezetés lehetősége. Erre pedig nekünk szülőknek kell felkészülni lelkiekben. Nincs ezzel sem semmi baj. :)</p>
          <p>Szeretettel várunk Titeket!</p>
          `,
          image: "hogyan-keszitsd-fel-gyermeked-a-kicsikocsikazasra.jpg",
          date: {
            year: "2023",
            month: "03",
            day: "20",
          },
        },
        {
          id: 6,
          slug: "mit-es-hogyan-fejlesztenek-az-elektromos-kisautok",
          title: "Mit és hogyan fejlesztenek az elektromos kisautók?",
          excerpt:
            "Mire is jók ezek a kisautók, és mit is nyer vele szülő és gyerek egyaránt? Összefoglalónkban sorra vesszük az elektromos kisautók előnyeit, fejlesztő hatásait.",
          content: `
          <p>Első ránézésre jogosan gondolhatnánk, hogy ezek a manapság már egyáltalán nem olcsó távirányítású elektromos kisautók felesleges luxus játékok a modern kor gyermekei számára. De mire is jók mégis ezek a kisautók, és mit is nyer vele szülő és gyerek egyaránt. Összefoglalónkban sorra vesszük az elektromos kisautók előnyeit, fejlesztő hatásait.</p>
          <h5>Motoros készségek, szem-kéz koordináció és a reakcióidő</h5>
          <p>Az elektromos kisautók irányítása és a kisautók sebességének szabályozása kihívást jelenthet a gyermekek számára. A lábbal hajtható gyermekjárművekkel ellentétben az akkumulátorral működtetett elektromos kisautók sebessége több fokozatban is állítható, ezért a gyermeknek van lehetősége a saját temperamentumához állítani azt. (Ezek a sebességek a szülő számára még gyalogosan követhetőek.) A kisautók vezetése közben szükséges az alsó test izmainak használata is, mely segíti a lábak és a karok koordinált működésének fejlődését. A gyerekautók méretben kisebbek és könnyebbek, mint az igazi autók, ezért a gyermekeknek a felnőttekkel ellentétben nagyobb szükségük van az egyensúlyérzékükre és a testkoordinációjukra, ezáltal a gyerekek ezen a téren is játékosan fejlődnek. Az elektromos autók segítségével a gyermekek megtanulhatják, hogyan kell kanyarodni, megfordulni és manőverezni, ami elősegíti a motoros készségeik és a térbeli tájékozódásuk fejlődését. A kormányzás közben a gyerekeknek meg kell tanulniuk azt is, hogyan kell a kormánykerékkel navigálniuk a kisautót. Ez amellett, hogy a kisautó önálló irányítását eredményezi, szintén fejleszti a finom motoros készségeket. A kisautók irányítása és a környezetre való figyelem együtt van jelen vezetés közben, így e kettő tevékenység közben a gyermekeknek össze kell hangolniuk a szemük és a kezük mozgását is, javítva így a szem-kéz koordinációjukat és a reakcióidőt.</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/01.jpg" alt="">
          </picture>
          <h5>Szórakoztató szabadtéri tevékenység és az érzékelés fejlesztése</h5>
          <p>Az elektromos kisautók segítenek a szabadtéri játék izgalmassá és szórakoztatóbbá tételében, és az egész család számára élvezetes élményt nyújtanak. Az újdonság varázsa, a vezetés élménye, az új kihívások motiválják őket a szabadban történő játszásra akár minden nap, ami az egészségükre is jó hatással van. Az autók akkumulátora egészen +7 fokig bírják az időjárás csökkenését, így akár az őszi vagy a kora tavaszi, de az enyhébb téli időszakokban is kiváló időtöltést nyújtanak számukra. Az elektromos autók vezetésekor a gyerekeknek folyamatosan figyelniük kell a környezetüket, a társaikat, esetleg a többi közlekedő kisautót. Meg kell felelniük a közterületek kihívásainak, például a szűk helyeknek vagy a nehezen kanyarodó utakon való közlekedésnek, mely segíti őket az érzékelési készségeik fejlődésében.</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/02.jpg" alt="">
          </picture>
          <h5>Önállóság érzése, önbizalom növelése</h5>
          <p>Az elektromos kisautók vezetése lehetővé teszi a gyermekek számára, hogy függetlenebbnek érezzék magukat, mivel ők irányítják a számukra misztikus tárgyat, az igazi kisautót. Az irányítás tudata növeli a saját magukba vetett hitet, bátorságot és magabiztosságot adva. Önbizalmuk mellet azonban az önállóságuk fejlődését is nagyban segíti. Az elektromos kisautó vezetés tehát lehetővé teszi, hogy a gyermek szabadon mozogjon, és döntéseket hozzon a sebesség - gyorsulás és a lassulás- és az irányítás vonatkozásában. Ez a gyerekek számára egy izgalmas kihívás, és segít nekik megtanulni, hogyan kezeljék a gyors mozgásokat.</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/03.jpg" alt="">
          </picture>
          <h5>Reakcióidő kialakítása és fejlesztése</h5>
          <p>Az igazi autók vezetése közben számos helyzet, kihívás adódik. Nincs ez másként az elektromos kisautók vezetése közben sem, amit a gyermekeknek is azonnal kell lereagálniuk, így a gyors döntések sorozata a határozottságuk és a reakcióidejük erősítéseben is nagy szerepet kap. A járdán közlekedve is van forgalom, figyelniük kell a közlekedő gyalogosokra, rolleresekre, kutyákra, melyek észlelése után gyors cselekvés szükséges a gyermekek részéről is. Pillanatok alatt kell dönteniük, hogy irányt váltanak, vagy épp megállnak. Sőt, mivel tőlünk, szülőktől az igazi autóban ülve elsőbbségadást is tanulnak, ezt a készséget a kisautóban ülve saját magukon is kialakíthatják, fejleszthetik. Minél több ilyen helyzettel találkoznak a gyermekek az élményvezetések alatt, annál inkább válik készségükké a gyors döntés, a határozott fellépés, vagy akár az elsőbbségadás (engedékenység) készsége, melyet az életük egyéb területein is jól be tudnak majd építeni. Legyen az egy óvodai udvari játék vagy egy rolleres utcai közlekedés.</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/04.jpg" alt="">
          </picture>
          <h5>Kreativitás fejlesztése</h5>
          <p>Az elektromos kisautók elérhetővé teszik a gyermekek számára azt a képzeletbeli felnőtt világot, melyben ők irányítanak egy számukra olyan misztikus járművet, mint egy autó. Kreativitásukat és képi világukat szabadon kiélve olyan valós, a felnőtt világból elsajátított szituációkon keresztül fejleszthetik, amit egy hagyományos játékkal játszva nincs lehetőségük megtapasztalni. Tervezhetnek útvonalat a játszótér és az óvoda között, képzeletben megállhatnak egy benzinkúton tankolni, vagy épp autót mosni. De az éppen stoppoló kis barátaikat is felvehetik utasnak. Ezek a fajta tevékenységek nagyban elősegítik a kreativitásuk és személyiségük fejlődését.</p>
          <h5>Koncentráció, figyelem, térbeli tájékozódás fejlesztése</h5>
          <p>Az elektromos kisautók vezetése során a gyerekeknek koncentrálniuk kell a jármű vezetésére, figyelniük kell a környezetüket és az ott történő folyamatos változásokat. Mivel ezek szinte egy időben történő események, a vezetés nagyban segít nekik abban, hogy javítsák figyelmüket és koncentrációképességüket. A kisautók használata segíthet a gyerekeknek abban is, hogyan javítsák térbeli tájékozódási képességüket. Az autó vezetése során a gyerekeknek meg kell tanulniuk, hogyan navigáljanak környezetükben, miközben figyelik a jármű sebességét és irányát</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/05.jpg" alt="">
          </picture>
          <h5>Társas játék</h5>
          <p>Az elektromos kisautók lehetőséget adnak a gyerekeknek arra, hogy közösen játszanak, ezáltal segítenek a szociális készségek kialakításában, fejlesztésében. Néhány gyermek együttes elektromos autókázása közben már számos közlekedési szituáció kialakul a játék során, melybe akár belevihetik saját elképzeléseik megvalósítását is. Ezen kívül az elektromos autó használata a gyerekek számára lehetőséget ad arra, hogy megtanulják, hogyan kell különböző forgalmi szabályokat és jelzéseket követni, ami további fejlesztési lehetőséget jelent a motoros készségeik számára. Mindeközben megtanulják a biztonságos közlekedés alapjait.</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/06.jpg" alt="">
          </picture>
          <h5>Hosszú üzemidő</h5>
          <p>Az elektromos kisautók hosszabb ideig működnek, mint a hagyományos játékok, így a gyerekek számára hosszabb ideig biztosítanak szórakozást.Amíg az akkumulátor le nem merül, újabb és újabb helyzetek alakulnak a gyerekek körül, melyek sorra új ötletekkel árasztják el őket fokozva így a játék élményét.</p>
          <br>
          <p>Összességében az elektromos kisautók használata a gyerekek számára olyan szórakoztató és interaktív fejlesztő tevékenység, melyek játékosan javítja a motoros készségeiket, a szem- kéz koordinációjukat, az önállóságukat, bátorságukat, határozottságukat, felelősségtudatukat, önfegyelmüket, fejlesztik a koncentrációjukat, és mindemellett szórakoztató marad hosszú időn keresztül.</p>
          <p>Mi szülők, vagy nagyszülők pedig örömmel nézhetjük, amint ezek a pici gyermekek elsajátítják a vezetés csínját-bínját. :)</p>
          <picture>
            <img src="../images/blog/mit-es-hogyan-fejlesztenek-az-elektromos-kisautok/07.jpg" alt="">
          </picture>
          `,
          image: "mit-es-hogyan-fejlesztenek-az-elektromos-kisautok.jpg",
          date: {
            year: "2023",
            month: "03",
            day: "19",
          },
        },
        {
          id: 5,
          slug: "milyen-elektromos-kisautot-vegyek-vegyek-egyaltalan",
          title: "Milyen elektromos kisautót vegyek? Vegyek egyáltalán?",
          excerpt:
            "A vásárlás szempontjai szakértő szemmel: Hol tudom használni a kisautókat? Mik egy kisautó előnyei és hátrányai?",
          content: `
          <p>A tavasz beköszöntével sok szülőnek jut eszébe, hogy kinti játékokat vásároljon gyermekének. A távirányítható, akkumulátorral működő elektromos gyerek kisautók egyre népszerűbbek a gyermekek és szülők körében, és egyben remek fejlesztő lehetőséget jelentenek a szabadban való szórakozás mellett. Azonban, mielőtt vásárolni indulnánk, érdemes tájékozódni. Mint mindennek, az elektromos kisautóknak is vannak előnyei és hátrányai. A fejlesztő hatásuk bemutatása után néhány gondolatban végig vesszük ezeket is.</p>
          <p>A legtöbben ezekre a járművekre, drága, luxus játékokként gondolnak, pedig a játékos szórakozás mellett számtalan fejlesztő és tanító hátasa is van a gyermekek számára. Az egyik ilyen például, hogy segítik a gyermekek motoros készségeinek fejlesztését, mivel az irányítás és a sebesség szabályozása egy időben komoly kihívást jelenthet számukra. Emellett segíti a különböző mozgáskoordinációs problémával küzdő gyermekek összehangolt mozdulatsorok szerinti képességeik fejlesztését. A szem-kéz, illetve a kéz-láb koordinációk fejlesztésére vagy tökéletesítésére is nagyszerű lehetőséget biztosítanak ezek a járművek. A problémákkal, nehézségekkel küzdő gyermekek számára okozhatnak kellemetlen pillanatokat a különböző terápiák alkalmával végzendő feladatsorok. A kisautókat vezetve úgy tesznek szeret egyre ügyesebben készségeik erősítésére, hogy közben szórakoznak és a szabadban is lehetnek.</p>
          <h5>Hol tudom használni a kisautókat?</h5>
          <p>Az otthonod körül, ha biztonságosan autózható járdákkal vagy körülvéve. Ezek lehetnek egy panelházak által határolt területen, vagy családi házak között megbújó csendesebb utcákon. De, hogy igazán élvezetes legyen a vezetés élménye, érdemes felkeresnetek a közeletekben lévő, kifejezetten gyermekek számára kialakított KRESZ parkokat, ahol kismotorral, rollerrel, de akár kerékpárral is tudnak közlekedni a kisautók melett. Budapesten és Pest megyében több ilyet is találunk, néhány ötlet a teljesség igénye nélkül:</p>
          <ul>
            <li>IV. kerületi Szilas Családi park</li>
            <li>XVI. kerületi Szilas patak KRESZ park</li>
            <li>Városligeti KRESZ park</li>
            <li>XVIII. kerületi KRESZ park</li>
            <li>Csepeli Bringaváros</li>
            <li>XI. kerületi KRESZ parkok</li>
            <li>II. kerületi KRESZ park</li>
          </ul>
          <p>És akkor nézzük, a fentieken kívül milyen előnyei vannak még az elektromos kisautóknak, de beszélünk a hátrányairól is.</p>
          <p>Előnyei között a már említett készségek fejlesztésén túl mindenképp beszélnünk kell a vezetés közben elsajátított KRESZ szabályok alapjairól is. A kisautókban ülve a gyermekek a felnőttek világában érzik magukat, és annak gyermeki komolyságával igyekeznek is megfelelni a felnőtt szabályoknak. Vezetés közben aktívabban figyelnek a KRESZ táblákra, az elsőbbségadás menetére, vagy akár az előzés szabályaira. De meg kell említenünk a tolatási és parkolási készségeik kialakulását és autómatizálódását. Ezen készségek elraktározódnak a gyermekben, mint például a kerékpározás tudománya, majd, amikor már igazi autón tanulhatnak, nem lesz teljesen ismeretlen terep számukra.</p>
          <p>Hátrányai ezen játékoknak a hagyományos játékokhoz képest, hogy nagy helyet igényel a tárolása és a szállítása, elektromos mivolta miatt áramot fogyaszt, és nem utolsó sorban az akkumulátorok élettartama sem véges, illetve ezen alkatrész nagyobb figyelmet igényel az élettartama meghosszabbítása érdekében.</p>
          <p>A leírtakkal kapcsolatban bármi kérdésetek felmerül, keressetek minket a <a href="/kapcsolat/">Kapcsolat</a> menüben megadott elérhetőségeinken.</p>
          <p>Az elektromos kisautók bérlésének még egy nagy előnye, hogy lehetőséget biztosít a gyermek számára annak kipróbálására, illetve a szülők is meggyőződéssel és tapasztalatokkal rendelkezve a megfelelő járművet vásárolják meg gyermekük számára. Keressetek fel minket akár vásárlás előtt álltok, akár bérelni szeretnétek, üzletünkben minden esetben a számotokra legjobb megoldással várunk.</p>
          `,
          image: "milyen-elektromos-kisautot-vegyek-vegyek-egyaltalan.jpg",
          date: {
            year: "2023",
            month: "03",
            day: "17",
          },
        },
        {
          id: 4,
          slug: "ujra-itt-a-tavasz",
          title: "Újra itt a tavasz!",
          excerpt:
            "A jó idő beköszöntével egyre több család keresi a szabadban a kikapcsolódási lehetőségeket. Gyere és kölcsönözz tőlünk egy kisautót és ismertesd meg gyermekeddel is a vezetés élményét.",
          content: `
            <p>Olyan szabadtéri programot keresel, amit nem csak a gyermeked élvez, hanem te is szülőként?</p>
            <p>A jó idő beköszöntével egyre több család keresi a szabadban a kikapcsolódási lehetőségeket. A rohanó hétköznapok levezetéseként jól esik a nyugodt hétvégi pihenés, a közösen eltöltött ebéd utáni kellemes sétával egybekötött program, ahol újdonság lehet az egész család számára egy elektromos kisautózás akár az otthonunk körül, akár távolabbi területeket felfedezve.</p>
            <p>Az alábbiakban adunk néhány ötletet és helyet egy kellemes családi kikapcsolódáshoz. Gyere és kölcsönözz tőlünk egy kisautót és ismertesd meg gyermekeddel is a vezetés élményét.</p>
            <p>Több különböző kisautó közül választhatsz, amelyek szülői távirányítóval és biztonsági övvel is rendelkeznek, így biztos lehetsz benne, hogy akár kisebb gyermeked is a legnagyobb védelem mellett élvezheti a nagyok világát.</p>
            <p>Az üzletünk körüli helyszíni élményvezetés mellett otthoni bérlésre is van lehetőség, legyen szó egy átlagos hétvégéről vagy egy hosszú hétvégéről, de akár a közelgő tavaszi szünet napjait is feldobhatod egy nem mindennapi szórakozási lehetőséggel.</p>
            <p>Válogass honlapunkon a különböző típusok közül és foglalj most egy kisautót a tavaszi időszakra!</p>
            <p>Tarts velünk, és tegyük együtt emlékezetessé a tavaszi szezont a gyermeked számára!</p>
            <p>Bővebb információt a <a href="/kapcsolat/">kapcsolat</a> menüben vagy a <a href="/blog/">blog</a> bejegyzések között kaphatsz.</p>
          `,
          image: "ujra-itt-a-tavasz.jpg",
          date: {
            year: "2023",
            month: "03",
            day: "01",
          },
        },
        {
          id: 3,
          slug: "oszi-teli-nyitvatartas",
          title: "Az őszi és téli időszakban is nyitva vagyunk!",
          excerpt:
            "Hétfőtől vasárnapig, telefonos bejelentkezés alapján 09:00 - 18:00 óra között. A sötét nem akadály, csak az eső vagy a hó.",
          content: `
            <p>Hétfőtől vasárnapig, telefonos bejelentkezés alapján 09:00 - 18:00 óra között. A sötét nem akadály, csak az eső vagy a hó.</p>
          `,
          image: "oszi-teli-nyitvatartas.jpg",
          date: {
            year: "2022",
            month: "09",
            day: "01",
          },
        },
        {
          id: 2,
          slug: "kozlekedj-okosan-elektromos-kisautoval-tabor",
          title: "Közlekedj okosan, elektromos kisautóval tábor: augusztus 15-19",
          excerpt:
            "Az akkumulátorral működő kis járgányok szórakoztató, fejlesztő elfoglaltságot nyújtanak az apró sofőröknek, és játékosan tanítanak a közlekedési szabályokra.",
          content: `
            <p>Az akkumulátorral működő kis járgányok izgalmas, szórakoztató és egyben fejlesztő elfoglaltságot nyújtanak az apró sofőröknek, a valódi vezetés élményét nyújtva. Játékosan tanítanak a közlekedési szabályokra, és azok betartására.</p>
            <p>Ajánlott korosztály: 5-7 éves korig, kizárólag 30 kg alatti és délutáni alvást nem igénylő gyermekek részére</p>
            <p>Reggel 8.00 órától, és délután 17.00 óráig tudunk ügyeletet biztosítani</p>
            <p>Időpont: Augusztus 15 - 19.
            <br>Helyszín: Kikötő Ifjúsági Sziget, 1156 Budapest Száraznád u. 5.
            <br>Részvételi díj napi háromszori étkezéssel: 39 000 Ft</p>
            <p>További információk: <a href="tel:+36308686087">+36 30 868 6087</a>-es telefonszámon vagy e-mailben a <a href="mailto:kikoto@csokonai15.hu">kikoto@csokonai15.hu</a> e-mail címen</p>
            <p>Jelentkezés: <a href="https://esemenyek.csokonai15.hu/tabor-2022-kikoto-ifjusagi-sziget/">https://esemenyek.csokonai15.hu/tabor-2022-kikoto-ifjusagi-sziget/</a></p>
          `,
          image: "kozlekedj-okosan-elektromos-kisautoval-tabor.png",
          date: {
            year: "2022",
            month: "04",
            day: "30",
          },
        },
        {
          id: 1,
          slug: "megnyitottuk-uzletunket-ujpesten-a-virag-utcaban",
          title: "Megnyitottuk üzletünket Újpesten a virág utcában!",
          excerpt:
            "Újpesten a Virág utcában megnyitottuk elektromos gyerekautó kölcsönzőnket, így már a kicsi gyerekek számára is elérhetővé vált a vezetés élménye!",
          content: `
          <p>Megnyitottuk elektromos gyerekautó kölcsönzőnket, így már a kicsi gyerekek számára is elérhetővé vált a vezetés élménye!</p>
          <p>Budapest 4. kerületében, a Virág utca 17. szám alatt, parkos környezetben várunk Titeket sok szeretettel!</p>
          <p>Parkolni az üzlet környezetében ingyenesen tudtok!</p>
          `,
          image: "megnyitottuk-uzletunket-ujpesten-a-virag-utcaban.jpg",
          date: {
            year: "2022",
            month: "03",
            day: "01",
          },
        },
      ],

      galleryCategories: [
        /*"Otthon", "Születésnapon", "Rendezvényen", "Üzletünknél"*/
      ],

      galleryItems: [
        /*
        {
          title: "Example youtube video",
          thumbnail: "https://img.youtube.com/vi/3nQNiWdeH2Q/hqdefault.jpg",
          src: "https://www.youtube.com/watch?v=3nQNiWdeH2Q",
          type: "video",
          categories: ["category-1", "category-2"],
          cars: ["car-1", "car-2"],
        },
        {
          title: "Example google storage video",
          thumbnail: "https://loremflickr.com/200/100/car?random=1",
          src: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          type: "video",
          categories: ["category-1", "category-2"],
          cars: ["car-1", "car-2"],
        },*/
      ],
    };
  },
  methods: {
    getRoutePath(routeName) {
      let resultRoute;

      const routes = this.$router.getRoutes();
      routes.map((route) => {
        if (route.name === routeName) {
          resultRoute = route;
        }
      });

      return resultRoute.path;
    },

    goTo(routeName) {
      window.location.href = this.getRoutePath(routeName);
      this.$router.push(this.getRoutePath(routeName));
    },
  },
};
